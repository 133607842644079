import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { getDocuments } from "../actions/documents";
import { useHistory, useLocation } from "react-router-dom";
import {
  DOCUMENTS,
  DOCUMENTS_INVESTOR_UPDATES,
  DOCUMENTS_ONBOARDING,
  DOCUMENTS_STATEMENTS,
  DOCUMENTS_TAX,
} from "../constants/routes";
import {
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import DocumentsList from "./DocumentsList";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: "49px",
      padding: "36px 0",
    },
  },
  paper: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    minHeight: "500px",
    position: "relative",
  },
  tab: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontFamily: "Gill Sans Std",
    fontWeight: 400,
    letterSpacing: "0.5px",
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
}));

function getSectionTitle(pathname) {
  if (pathname === DOCUMENTS_INVESTOR_UPDATES) {
    return "Investor Letters & Briefings";
  }
  if (pathname === DOCUMENTS_TAX) {
    return "Schedule K-1s & Tax Forms";
  }
  if (pathname === DOCUMENTS_ONBOARDING) {
    return "LP Documents";
  }
  return "Account Statements";
}

const propTypes = {};

const defaultProps = {};

export default function Documents(props) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { authState } = useOktaAuth();
  const history = useHistory();
  const location = useLocation();

  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const user = useSelector((state) => state.user);

  const [getDocumentsHasBeenCalled, setGetDocumentsHasBeenCalled] = useState(
    false
  );

  useEffect(() => {
    if (user.id) {
      dispatch(getDocuments(user.id, authState.accessToken.value))
        .then(() => setGetDocumentsHasBeenCalled(true))
        .catch(() => setGetDocumentsHasBeenCalled(true));
    }
  }, [user, dispatch, authState]);

  useEffect(() => {
    if (location.pathname === DOCUMENTS) {
      history.push(DOCUMENTS_STATEMENTS);
    }
  }, [location, history]);

  return (
    <Paper className={classes.paper}>
      {isMediumScreen && (
        <Tabs
          value={location.pathname}
          className={classes.tabs}
          onChange={(e, newValue) => history.push(newValue)}
          indicatorColor="primary"
        >
          <Tab
            value={DOCUMENTS_STATEMENTS}
            label="Account Statements"
            className={classes.tab}
          />
          <Tab
            value={DOCUMENTS_TAX}
            label="Tax Documents"
            className={classes.tab}
          />
          <Tab
            value={DOCUMENTS_ONBOARDING}
            label="LP Documents"
            className={classes.tab}
          />
          <Tab
            value={DOCUMENTS_INVESTOR_UPDATES}
            label="Investor Updates"
            className={classes.tab}
          />
        </Tabs>
      )}
      <Grid container className={classes.main}>
        <Grid item xs={12} md={5}>
          {isMediumScreen && (
            <Typography variant="h5">
              {getSectionTitle(location.pathname)}
            </Typography>
          )}
        </Grid>
        <DocumentsList getDocumentsHasBeenCalled={getDocumentsHasBeenCalled} />
      </Grid>
    </Paper>
  );
}

Documents.propTypes = propTypes;
Documents.defaultProps = defaultProps;
