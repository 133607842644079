import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const propTypes = {
  classes: PropTypes.object.isRequired,
};

export default function LoadingDocumentItem({ classes }) {
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div className={classes.doc}>
      {isExtraSmallScreen ? (
        <div className={classes.docLeft}>
          <Skeleton width={130} height={10} animation="wave"></Skeleton>
          <div className={classes.docTitle}>
            <Skeleton width={220} height={16} animation="wave"></Skeleton>
          </div>
          <Skeleton width={170} height={12} animation="wave"></Skeleton>
        </div>
      ) : (
        <div className={classes.docTitle}>
          <Skeleton width={130} height={10} animation="wave"></Skeleton>
          <Skeleton width={220} height={16} animation="wave"></Skeleton>
          <Skeleton width={170} height={12} animation="wave"></Skeleton>
        </div>
      )}
      <Skeleton
        variant="rect"
        width={132.56}
        height={41}
        animation="wave"
        className={classes.buttonWrapper}
      ></Skeleton>
    </div>
  );
}

LoadingDocumentItem.propTypes = propTypes;
