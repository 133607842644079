import { getFromCastleAPI } from "../util/api-util";
import { RECEIVE_DOCUMENTS } from "../constants/actions";

export const getDocuments = (userId, accessToken) => (dispatch) =>
  getFromCastleAPI(`documents/users/${userId}`, accessToken)
    .then((response) =>
      dispatch(receiveDocuments(response.data.data.documents))
    )
    .catch((error) => {
      console.log("[getDocuments] error: ", error);
      throw error;
    });

export const receiveDocuments = (data) => ({
  type: RECEIVE_DOCUMENTS,
  data,
});
