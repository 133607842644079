import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getUser } from "../actions/user";
import { getBalances } from "../actions/balances";
import {
  makeStyles,
  Container,
  Box,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Copyright from "./Copyright";
import { getErrorMessage, isDev } from "../util/helpers";
import { ERROR_SNACKBAR } from "../constants/properties";
import { getFundInfo, setShowFundHoldingsDisclaimer } from "../actions/funds";
import { getUserProfile } from "../actions/profile";
import { getUserTxHistory } from "../actions/txs";
import BottomNav from "./BottomNav";
import Disclaimer from "./Disclaimer";
import { DASHBOARD } from "../constants/routes";
import ReactGA from "react-ga";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    [theme.breakpoints.up("md")]: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
  },
  container: {
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0, //this creates problem on mobile bottom nav when dashboard is selected
      paddingRight: 0,
    },
  },
  root: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
}));

const propTypes = {
  children: PropTypes.element,
};

const defaultProps = {};

export default function Portal({ children }) {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const { authState } = useOktaAuth();
  const { enqueueSnackbar } = useSnackbar();

  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const user = useSelector((state) => state.user);
  const balances = useSelector((state) => state.balances);
  const funds = useSelector((state) => state.funds);
  const fundHoldingsDisclaimer = useSelector(
    (state) => state.fundHoldingsDisclaimer
  );

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [gaHasBeenInitialized, setGaHasBeenInitialized] = useState(false);

  // Get userId for all future calls
  useEffect(() => {
    dispatch(getUser(authState.accessToken.value)).catch((error) =>
      enqueueSnackbar(getErrorMessage(error), ERROR_SNACKBAR)
    );
  }, [dispatch, authState, enqueueSnackbar]);

  // Get each fund balance on an interval
  useEffect(() => {
    const makeNewGetBalancesCall = () =>
      dispatch(getBalances(user.id, authState.accessToken.value)).catch(
        (error) => console.log("[Portal] getBalances error: ", error)
      );

    let interval;
    if (user.id) {
      interval = setInterval(() => makeNewGetBalancesCall(), 60000);
      makeNewGetBalancesCall();
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [authState, user, dispatch]);

  // Get each fund info when new fundId is found
  useEffect(() => {
    balances
      .filter((fund) => !funds.hasOwnProperty(fund.fundId))
      .map((fund) =>
        dispatch(getFundInfo(fund.fundId, authState.accessToken.value)).catch(
          (error) => enqueueSnackbar(getErrorMessage(error), ERROR_SNACKBAR)
        )
      );
  }, [authState, balances, dispatch, funds, enqueueSnackbar]);

  // Get user profile to show name on navbar
  useEffect(() => {
    user.id &&
      dispatch(getUserProfile(user.id, authState.accessToken.value)).catch(
        (error) => enqueueSnackbar(getErrorMessage(error), ERROR_SNACKBAR)
      );
  }, [authState, enqueueSnackbar, user.id, dispatch]);

  // Get user subscriptions & withdrawals
  useEffect(() => {
    user.id &&
      dispatch(getUserTxHistory(user.id, authState.accessToken.value)).catch(
        (error) => enqueueSnackbar(getErrorMessage(error, ERROR_SNACKBAR))
      );
  }, [authState, user, dispatch, enqueueSnackbar]);

  // // Open or close sidebar based on screen size
  useEffect(() => {
    setIsSidebarOpen(isLargeScreen);
  }, [isLargeScreen]);

  // // Initialize Google Analytics, Update page views on location change
  useEffect(() => {
    if (user.id && !gaHasBeenInitialized) {
      ReactGA.initialize(isDev() ? "UA-175503783-2" : "UA-175503783-1", {
        gaOptions: {
          userId: user.id,
        },
      });
      console.log("[Portal] ReactGA initialized");
      setGaHasBeenInitialized(true);
    }
    ReactGA.pageview(location.pathname + location.search);
  }, [user, gaHasBeenInitialized, location]);

  // // Show fund holdings disclaimer on first load if user is invested in active fund, disclaimer is not shown, and user has not clicked to acknowledge
  useEffect(() => {
    if (
      Object.keys(funds).includes("004cb3674d314e7d9e08e8540b0ace4c") &&
      !fundHoldingsDisclaimer.isShowDisclaimer &&
      !fundHoldingsDisclaimer.userAcknowledged
    ) {
      dispatch(setShowFundHoldingsDisclaimer(true, false));
    }
  }, [funds, fundHoldingsDisclaimer, dispatch]);

  return (
    <div className={classes.root}>
      <Navbar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      {isMediumScreen && (
        <Sidebar open={isSidebarOpen} setOpen={setIsSidebarOpen} />
      )}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {children}
          {location.pathname === DASHBOARD && (
            <Box pt={4}>
              <Disclaimer />
            </Box>
          )}
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
      {!isMediumScreen && <BottomNav />}
    </div>
  );
}

Portal.propTypes = propTypes;
Portal.defaultProps = defaultProps;
