import moment from "moment";

export function isDevDev() {
  const host = window.location.host;
  return (host.includes("dev."));
}

export function isDev() {
  const host = window.location.host;
  return (
    host.includes("dev.") ||
    host.includes("localhost") ||
    host.includes("stage") ||
    host.includes("demo")
  );
}

export function getServerURL() {
  const host = window.location.host;
  if (host.includes("localhost")) {
    return "https://stage.castlefunds.com";
  } else {
    if (host.includes("dev.")) {
      return "https://dev.castlefunds.com";
    } else if (host.includes("stage") || host.includes("demo")) {
      const dom = host.substr(host.lastIndexOf("."));
      return "https://stage.castlefunds" + dom;
    } else {
      return "https://api.castlefunds.com";
    }
  }
}

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const formatNumberAsDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatWithCommas = (numberString) => {
  const noCommas = numberString.replace(/,/g, "");
  return noCommas.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const dollarize = (n) => {
  if (typeof n !== "number") return "";
  return formatNumberAsDollar.format(n);
};

export const decimalize = (n, min, max) => {
  if (typeof n !== "number") return "";
  return n.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: min || 8,
    maximumFractionDigits: max || 8,
  });
};

export const percentize = (decimal, min, max) => {
  if (typeof decimal !== "number") return "";
  return max === 0
    ? `${(decimal * 100).toFixed(0)}%`
    : `${(decimal * 100).toLocaleString("en-US", {
        style: "decimal",
        minimumFractionDigits: min || 2,
        maximumFractionDigits: max || 2,
      })}%`;
};

export const datestringize = (date) =>
  date && moment(date).utc().local().format("MMM D, YYYY");

export const datestringizeWithTime = (date) =>
  date && moment(date).utc().local().format("MMM D, YYYY h:mm:ss a");

export const datestringizeLongMonth = (date, utc) =>
  date && utc
    ? moment(date).utc().format("MMMM D, YYYY")
    : moment(date).utc().local().format("MMMM D, YYYY");

export const timestringize = (date) =>
  date && `${moment(date).utc().local().format("h:mm:ss a")}`;

export function getErrorMessage(error) {
  if (
    typeof error === `object` &&
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    return error.response.data.message;
  } else if (
    typeof error === `object` &&
    error.response &&
    error.response.data &&
    error.response.data.error &&
    typeof error.response.data.error === `string`
  ) {
    return error.response.data.error;
  } else if (typeof error === `object` && error.message) {
    console.log("error message fail 1 ", error);
    return error.message;
  } else {
    console.log("error message fail 2 ", error);
    return "Something went wrong";
  }
}

export const netCompletedTransactionsAfterISOString = (txs, isoString) => {
  return txs
    .filter((tx) =>
      !!isoString
        ? tx.state === "COMPLETE" && tx.lastUpdatedOn > isoString
        : tx.state === "COMPLETE"
    )
    .reduce(
      (accum, curr) =>
        curr.type === "SUBSCRIPTION"
          ? (accum += curr.amount)
          : (accum -= curr.amount),
      0
    );
};
