import React from "react";
import { Route, useHistory, Switch } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security, SecureRoute } from "@okta/okta-react";
import { oktaConfig } from "../okta";
import {
  DASHBOARD,
  DOCUMENTS,
  LOG_OUT,
  REDIRECT_URI,
  SETTINGS,
  SIGN_IN,
  TRANSACTIONS,
} from "../constants/routes";
import {
  createMuiTheme,
  CssBaseline,
  ThemeProvider,
  withStyles,
} from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import {
  iowanRoman,
  iowanBT,
  iowanBold,
  iowanItalic,
  iowanTitling,
  iowanBlack,
  iowanBlackItalic,
  gillSans,
  gillSansCondensed,
  gillSansBold,
  gillSansExtraBoldDisp,
  gillSansExtraBold,
  gillSansLightItalic,
  gillSansLightShadowed,
  gillSansItalic,
  gillSansUltraBoldCond,
  gillSansBoldItalic,
  gillSansUltraBold,
  gillSansExtraBoldCond,
  gillSansShadowed,
  gillSansBoldCond,
  gillSansLight,
} from "../fonts/fonts";
import OktaSignInWidget from "./OktaSignInWidget";
import Portal from "./Portal";
import LogOut from "./LogOut";
import Dashboard from "./Dashboard";
import Transactions from "./Transactions";
import Documents from "./Documents";
import Settings from "./Settings";
// import UnderConstruction from "./UnderConstruction";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: `#222C3A`,
    },
    secondary: {
      main: `#E6E6E5`,
    },
    grey: {
      main: "#e6e6e5",
      light: "#fafafa",
      medium: "#d8d8d8",
      dark: `#999`,
    },
    deltaGreen: {
      main: `#47ab00`,
    },
    deltaRed: {
      main: `#d00c0c`,
    },
    error: {
      main: "#d93900",
    },
    success: {
      main: `#47ab00`,
    },
    gold: {
      main: `#BBA070`,
    },
    babyBlue: {
      main: `#4576A4`,
    },
    white: {
      main: "#fff",
    },
    sideBarSelected: {
      main: "#080808",
    },
  },
  typography: {
    fontFamily: [
      "Iowan Old Style",
      "Gill Sans Std",
      "Roboto",
      "Arial",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    body2: {
      letterSpacing: "0.3px",
      fontFamily: "Gill Sans Std",
    },
    body1: {
      fontFamily: "Gill Sans Std",
      letterSpacing: "0.5px",
    },
    h3: {
      letterSpacing: "0.5px",
    },
    h6: {
      letterSpacing: "0.5px",
    },
    caption: {
      letterSpacing: "0.3px",
    },
    subtitle1: {
      letterSpacing: "0.3px",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          iowanRoman,
          iowanBT,
          iowanBold,
          iowanItalic,
          iowanTitling,
          iowanBlack,
          iowanBlackItalic,
          gillSans,
          gillSansCondensed,
          gillSansBold,
          gillSansExtraBoldDisp,
          gillSansExtraBold,
          gillSansLightItalic,
          gillSansLightShadowed,
          gillSansItalic,
          gillSansUltraBoldCond,
          gillSansBoldItalic,
          gillSansUltraBold,
          gillSansExtraBoldCond,
          gillSansShadowed,
          gillSansBoldCond,
          gillSansLight,
        ],
      },
    },
  },
});

const globalStyles = {
  "@global": {
    ".button-gillsans": {
      fontFamily: "Gill Sans Std",
      fontWeight: 400,
      letterSpacing: "0.5px",
      "& .MuiButton-label": {
        marginTop: "3px",
      },
    },
    ".MuiFormHelperText-root": {
      fontFamily:
        "Gill Sans Std,Roboto,Arial,-apple-system,BlinkMacSystemFont,'Segoe UI','Helvetica Neue',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
    },
    ".MuiTableCell-head": {
      fontWeight: 300,
    },
  },
};

const oktaAuth = new OktaAuth(oktaConfig);

export default withStyles(globalStyles)(function App() {
  const history = useHistory();

  const onAuthRequired = () => {
    history.push(SIGN_IN);
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };


  // useEffect(() => {
  //   oktaAuth.start();
  // }, [])

  // NB: Email all investors if extended downtime
  // return <UnderConstruction />;

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired} restoreOriginalUri={restoreOriginalUri}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <CssBaseline />
          <Switch>
            <Route path={REDIRECT_URI} component={LoginCallback} />
            <Route path={SIGN_IN} component={OktaSignInWidget} />
            <Route path={LOG_OUT} component={LogOut} />
            <SecureRoute
              exact
              path={DASHBOARD}
              render={() => (
                <Portal>
                  <Dashboard />
                </Portal>
              )}
            />
            <SecureRoute
              path={TRANSACTIONS}
              render={() => (
                <Portal>
                  <Transactions />
                </Portal>
              )}
            />
            <SecureRoute
              path={DOCUMENTS}
              render={() => (
                <Portal>
                  <Documents />
                </Portal>
              )}
            />
            <SecureRoute
              path={SETTINGS}
              render={() => (
                <Portal>
                  <Settings />
                </Portal>
              )}
            />
          </Switch>
        </SnackbarProvider>
      </ThemeProvider>
    </Security>
  );
});
