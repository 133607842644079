import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import DocumentIcon from "@material-ui/icons/Description";
import { datestringizeLongMonth, getErrorMessage } from "../util/helpers";
import { useOktaAuth } from "@okta/okta-react";
import ReactGA from "react-ga";
import Axios from "axios";
import { useSnackbar } from "notistack";
import { ERROR_SNACKBAR } from "../constants/properties";

const getDocumentSubtitle = (doc, funds) =>
  funds[doc.fundId]
    ? `${datestringizeLongMonth(doc.date, true)} | ${funds[doc.fundId].name}`
    : datestringizeLongMonth(doc.date, true);

const propTypes = {
  classes: PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
};

const defaultProps = {};

export default function DocumentItem({ doc, classes }) {
  const { authState } = useOktaAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [isDownloadingByDocId, setIsDownloadingByDocId] = useState({});

  const funds = useSelector((state) => state.funds);

  const download = async (downloadUrl, id, description) => {
    setIsDownloadingByDocId({ ...isDownloadingByDocId, [id]: true });
    ReactGA.event({
      category: "Documents",
      action: "Downloaded Document",
      label: description,
    });
    Axios({
      url: downloadUrl,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${authState.accessToken.value}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${description}.pdf`);
        link.click();
        setIsDownloadingByDocId({ ...isDownloadingByDocId, [id]: false });
      })
      .catch((error) => {
        setIsDownloadingByDocId({ ...isDownloadingByDocId, [id]: false });
        enqueueSnackbar(getErrorMessage(error), ERROR_SNACKBAR);
      });
  };
  return (
    <div className={classes.doc}>
      <div className={classes.docLeft}>
        <DocumentIcon className={classes.docIcon} />
        <div className={classes.docTitle}>
          <Typography variant="caption" className={classes.subtitle}>
            {getDocumentSubtitle(doc, funds)}
          </Typography>
          <Typography variant="h5">{doc.description}</Typography>
        </div>
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          variant="outlined"
          onClick={() => download(doc.downloadUrl, doc.id, doc.description)}
          disabled={isDownloadingByDocId[doc.id]}
          className="button-gillsans"
        >
          Download
        </Button>
        {isDownloadingByDocId[doc.id] && (
          <CircularProgress className={classes.buttonProgress} size={24} />
        )}
      </div>
    </div>
  );
}

DocumentItem.propTypes = propTypes;
DocumentItem.defaultProps = defaultProps;
