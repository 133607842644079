import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  DOCUMENTS_INVESTOR_UPDATES,
  DOCUMENTS_ONBOARDING,
  DOCUMENTS_TAX,
} from "../constants/routes";
import FolderIcon from "@material-ui/icons/FolderOutlined";
import MaterialTable from "material-table";
import DocumentItem from "./DocumentItem";
import LoadingDocumentItem from "./LoadingDocumentItem";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      alignSelf: "center",
      marginTop: "15px",
    },
  },
  divider: {
    width: "85%",
    margin: "0 auto",
  },
  doc: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // margin: theme.spacing(3),
    // "&:first-of-type": {
    //   marginTop: 0,
    // },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  docIcon: {
    height: 25,
    display: "block",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      marginBottom: theme.spacing(1),
    },
  },
  docLeft: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      margin: "0 auto",
      textAlign: "center",
    },
  },
  docTitle: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  empty: {
    textAlign: "center",
    margin: "0 auto",
    maxWidth: 250,
    marginTop: theme.spacing(5),
  },
  emptyText: {
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
  subtitle: {
    color: theme.palette.grey.dark,
  },
}));

const propTypes = {
  getDocumentsHasBeenCalled: PropTypes.bool.isRequired,
};

const defaultProps = {};

const getFilteredDocuments = (doc, location) => {
  if (location.pathname === DOCUMENTS_INVESTOR_UPDATES) {
    return doc.documentType === "GENERAL";
  }
  if (location.pathname === DOCUMENTS_TAX) {
    return doc.documentType === "TAX";
  }
  if (location.pathname === DOCUMENTS_ONBOARDING) {
    return doc.documentType === "ONBOARDING";
  }
  return doc.documentType === "STATEMENT";
};

export default function DocumentsList({ getDocumentsHasBeenCalled }) {
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();

  const documents = useSelector((state) => state.documents).filter((doc) =>
    getFilteredDocuments(doc, location)
  );

  if (getDocumentsHasBeenCalled && documents.length === 0) {
    return (
      <div className={classes.empty}>
        <FolderIcon />
        <Typography variant="body1">
          Documents will be shown here once they are made available.
        </Typography>
      </div>
    );
  }

  return (
    <Grid item xs={12} md={7}>
      <MaterialTable
        columns={[
          {
            render: (rowData) =>
              getDocumentsHasBeenCalled ? (
                <DocumentItem doc={rowData} classes={classes} />
              ) : (
                <LoadingDocumentItem classes={classes} />
              ),
          },
        ]}
        data={
          getDocumentsHasBeenCalled
            ? documents
            : Array.from(Array(5)).map(() => ({}))
        }
        components={{
          Header: () => null,
          Toolbar: () => null,
          Container: (props) => <div>{props.children}</div>,
        }}
        options={{
          cellStyle: {
            padding: theme.spacing(3),
          },
        }}
      />
    </Grid>
  );
}

DocumentsList.propTypes = propTypes;
DocumentsList.defaultProps = defaultProps;
